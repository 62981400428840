import React from 'react'
import classes from './partners.module.scss'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/free-mode'
import { FreeMode } from 'swiper/modules'

function Partners() {
  const swiper = [
    { id: '5', img: '/assets/img/saveElevator.jpg' },
    { id: '6', img: '/assets/img/schindler.png' },
    { id: '7', img: '/assets/img/torch.jpg' },
    { id: '8', img: '/assets/img/blt.png' },
    { id: '9', img: '/assets/img/larsson.jpg' },
    { id: '1', img: '/assets/img/sharbat.jpeg' },
    { id: '2', img: '/assets/img/farid-85.png' },
    { id: '3', img: '/assets/img/lohuti.png' },
    { id: '4', img: '/assets/img/mehavarSohtmon.svg' },
  ]
  return (
    <div id="partners" className={classes.partners}>
      <div className={classes.wrapper}>
        <h1 className={classes.h1}>Наши партнёры</h1>
        <div className={classes.swiper}>
          <Swiper
            slidesPerView={1.5}
            spaceBetween={10}
            freeMode={true}
            modules={[FreeMode]}
            centeredSlides={true}
            breakpoints={{
              592: {
                slidesPerView: 3,
                spaceBetween: 24,
              },
              1200: {
                slidesPerView: 3,
                spaceBetween: 24,
              },
            }}
            className={classes.mySwiper}
          >
            {swiper.map((el) => (
              <SwiperSlide key={el.id}>
                <div className={classes.slide}>
                  <img className={classes.img} src={el.img} alt="" />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  )
}

export default Partners
