import classes from './catalogs.module.scss'
function Catalogs() {
  const swiper = [
    {
      id: '5',
      img: '/assets/img/saveElevator.jpg',
      catalog: '/assets/elevator.pdf',
    },
    {
      id: '6',
      img: '/assets/img/schindler.png',
      catalog: '/assets/schindler.pdf',
    },
    {
      id: '7',
      img: '/assets/img/torch.jpg',
      catalog: '/assets/Torch-catalogue.pdf',
    },
    { id: '8', img: '/assets/img/blt.png', catalog: '/assets/BLT.pdf' },
    { id: '9', img: '/assets/img/larsson.jpg', catalog: '/assets/larsson.pdf' },
  ]
  return (
    <div className={classes.catalogs}>
      <div className={classes.wrapper}>
        <h1 className={classes.h1}>Наши каталоги</h1>
        <div className={classes.catalog}>
          {swiper.map((el) => (
            <div key={el.id} className={classes.slide}>
              <img className={classes.img} src={el.img} alt="" />
              <a target="_blank" href={el.catalog} className={classes.btn}>
                Скачать каталог
                <img
                  className={classes.downloadWhite}
                  src="/assets/img/logo/download.svg"
                  alt=""
                />
                <img
                  className={classes.downloadBlue}
                  src="/assets/img/logo/download-blue.svg"
                  alt=""
                />
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Catalogs
