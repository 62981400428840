import React from "react";
import classes from "./video.module.scss";

function Video() {
  return (
    <div className={classes.video}>
      <div className={classes.wrapper}>
        <h5 className={classes.h5}>Наш YouTube канал</h5>
        <iframe
          className={classes.youtube}
          width="100%"
          height="500"
          src="https://www.youtube.com/embed/8LRgpcWzeuY?si=IKYfjDJrFaRBfrQ-"
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        ></iframe>
      </div>
    </div>
  );
}

export default Video;
