import React from 'react'
import classes from './reviews.module.scss'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/free-mode'
import 'swiper/css/pagination'
import 'swiper/css/navigation'

import { FreeMode, Pagination, Navigation } from 'swiper/modules'

function Reviews() {
  return (
    <div id="reviews" className={classes.reviews}>
      <div className={classes.wrapper}>
        <h1 className={classes.h1}>Отзывы наших клиентов</h1>
        <div className={classes.swiper}>
          <Swiper
            slidesPerView={1}
            spaceBetween={24}
            freeMode={true}
            pagination={{
              clickable: true,
            }}
            navigation={{
              clickable: true,
            }}
            modules={[FreeMode, Pagination, Navigation]}
            breakpoints={{
              592: {
                slidesPerView: 2,
                spaceBetween: 24,
              },
              1200: {
                slidesPerView: 3,
                spaceBetween: 24,
              },
            }}
            className={classes.mySwiper}
          >
            <SwiperSlide>
              <div className={classes.card}>
                <img
                  className={classes.img}
                  src="/assets/img/user1.png"
                  alt=""
                />
                <h2 className={classes.title}>Хасан</h2>
                <p className={classes.paragraph1}>директор</p>
                <p className={classes.paragraph2}>
                  Уважаемая команда JV Group, Хочу выразить благодарность за ваш
                  продукт — лифты, которые вы предоставляете. Наш опыт с вами
                  был положительным. Качество работы лифтов просто впечатляет.
                  Мы готовы рекомендовать JV Group как надежного и качественного
                  партнера в области лифтовых решений. С наилучшими пожеланиями,
                  Хасан, Директор компании "Мехвар Сохтмон"
                </p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={classes.card}>
                <img
                  className={classes.img}
                  src="/assets/img/user1.png"
                  alt=""
                />
                <h2 className={classes.title}>Манучехри Чурахон</h2>
                <p className={classes.paragraph1}>директор</p>
                <p className={classes.paragraph2}>
                  Необходимо выделить, насколько важным является для нас, в
                  Чахон ИНвест Муст Транс LLC, своевременное выполнение заказов.
                  Партнерство с JV Group в этом аспекте оказалось ценным. Их
                  строгие сроки и организованный процесс доставки являются
                  неоспоримыми плюсами в нашем опыте сотрудничества. Доставка
                  всегда происходит вовремя, что предоставляет нам уверенность в
                  надежности JV Group как поставщика.
                </p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={classes.card}>
                <img
                  className={classes.img}
                  src="/assets/img/user1.png"
                  alt=""
                />
                <h2 className={classes.title}>Зафар</h2>
                <p className={classes.paragraph1}>директор</p>
                <p className={classes.paragraph2}>
                  Лифты от JV Group — высокое качество и надежность. Их
                  продукция соответствует самым строгим стандартам безопасности.
                  Сотрудничество с JV Group оценивается положительно за
                  профессионализм, оперативность и внимание к деталям.
                  Рекомендую как надежного поставщика лифтов.
                </p>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>
  )
}

export default Reviews
